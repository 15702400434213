let intervalId = 0;

$(document).on("keypress", "#searchbox-input.kyn-searchbox-input", (e) => {
    if (e.key === "Enter") {
        const container = $(".kyn-results-container");
        container.show();
        conditionallyHideShowMore();
    }
    changeSearchBarWidth();
});

$(document).on('click', function hideResults(e) {
  const container = $(".kyn-results-container");
  const input = $(".kyn-searchbox-input");
  if (!container.is(e.target) && !container.has(e.target).length && !input.is(e.target)) {
      container.hide();
      clearInterval(intervalId);
  }
});

$(document).on("mousedown", ".kyn-search-suggestion-item", (e) => {
    $(".kyn-results-container").show();
    conditionallyHideShowMore();
    changeSearchBarWidth();
});

$(document).on("click", "#searchbox-reset", function (e) {
    $("#kyn-search").css("width", "1px");
    let currentURL = window.location.href;
    currentURL = currentURL.substring(0, currentURL.indexOf("?"));
    history.replaceState(null, null, currentURL);
    $(".kyn-results-container").hide();
    clearInterval(intervalId);
});

let moved = false;
$(window).on("resize", (e) => {
    if ($(window).width() < 1001 && moved) {
        const kydSearchElement = $("#kyn-search");
        $(".nav-menu").prepend(kydSearchElement);
        moved = false;
    }
});

$(window).on("resize", (e) => {
    if ($(window).width() >= 1001 && !moved) {
        const kydSearchElement = $("#kyn-search");
        (kydSearchElement).insertAfter($(".topbar_row").children("a"));
        moved = true;
    }
});

$(document).on("turbolinks:load", (e) => {
    if ($(window).width() >= 1001) {
      const kydSearchElement = $("#kyn-search");
      (kydSearchElement).insertAfter($(".topbar_row").children("a"));
    }
});

const changeSearchBarWidth = () => {
  const length = $(".kyn-searchbox-input").val().length;
  const searchBar = $("#kyn-search");
  const width = length * 12;
  if (width > 300 ){
    if(width < parseInt(searchBar.css("max-width"), 10)) {
      searchBar.css("width", width + "px");
  } else {
      searchBar.css("width", parseInt(searchBar.css("max-width"), 10) + "px");
  }
}
}

const conditionallyHideShowMore = () => {
  const hide = () => {
    $(".kyn-document-sentences").each(function () {
      const numSentences = $(this).children(".sentence").length;
      if (numSentences === 1) {
        $(this).children().last().hide();
      }
    });
  }
  intervalId = setInterval(hide, 100);
}

$(document).on("click", ".results-expand", (e) => {
  const resultId = e.currentTarget.id;
  const resultsContainer = $(`#sentences-of-${resultId}`);
  const isSecondChildVisible = $(`#sentences-of-${resultId} div:nth-child(2)`).is(":visible");
  const lastChild = resultsContainer.children().last();
  if(isSecondChildVisible) {
    resultsContainer.children().hide();
    lastChild.show();
    lastChild.text("Show More +");
  } else {
    resultsContainer.children().show();
    lastChild.text("Show Less -");
  }
});