console.log("pdf.js load");

$(document).on("turbolinks:load", (event) => {
	var containers = $("*[data-pdf-url]");

	for (let x = 0; x < containers.length; x += 1) {
		let container = containers[x];
		loadPdf(container);
	}
});

const loadPdf = (container) => {
	const pdfUrl = $(container).attr('data-pdf-url');

	if (!container.pdf) {
		const loadingTask = pdfjsLib.getDocument(pdfUrl);
		loadingTask.promise.then(function(pdf) {
			container.pdf = pdf;
			renderPdf(container);
			container.pdfRender = () => {
				renderPdf(container);
			};
		});
	}
};

const renderPdf = (container) => {
	if (!container.pdf) {
		loadPdf(container);
	}
	
	const pageNum = parseInt($(container).attr('data-pdf-pagenum')) || 1;
	
	container.pdf.getPage(pageNum).then((page) => {
		if ($(container.firstChild)) {
			$(container.firstChild).remove();
		}
		container.innerHTML = '<canvas></canvas>';
		
		const scale = 1;
		const pdfViewport = page.getViewport({ scale: scale, });
		const outputScale = window.devicePixelRatio || 1;

		const canvas = container.children[0];
		const context = canvas.getContext('2d');
		
		canvas.width = Math.floor(pdfViewport.width * outputScale);
		canvas.height = Math.floor(pdfViewport.height * outputScale);
		
		const transform = outputScale !== 1
		  ? [outputScale, 0, 0, outputScale, 0, 0]
		  : null;

		const renderContext = {
		  canvasContext: context,
		  transform: transform,
		  viewport: pdfViewport
		};
		page.render(renderContext);
	});
};

const canvasToJpeg = (canvas) => {
	canvas.toBlob((blob) => { 
		console.log("Blob", blob);
		}, 'image/jpeg', 0.95);
};

const pagePreload = 3; // how many slides to load at a time
const maxKeep = 5; // how many slides ot keep loaded
const loadStack = [];
var currentPageIndex = 0;
var fundraiseId = undefined;
var recordPageViews = false;
var totalPagesCount = undefined;
var promptOnLastPage = false;
var slideDwellTimer = undefined;
var questionButtonHighlightCount = 0;
var questionButtonClicked = false;

$(document).on("turbolinks:load", (event) => {
	const container = $('#deck-slideshow-container');
	
	if (container.length === 0) {
		return;
	}
	
	const startPageNum = parseInt(container.attr('data-start-page'));
	fundraiseId = container.attr('data-fundraise-id');
	if (container.attr('data-record-page-views')) {
		recordPageViews = true;
	}
	if (container.attr('data-prompt-on-last-page')) {
		promptOnLastPage = true;
	}
	totalPagesCount = container.find('.deck-slideshow-page').length;
	
	console.log("Found slideshow container - start page, total pages, fundraise id, record page views", startPageNum, totalPagesCount, fundraiseId, recordPageViews);

	currentPageIndex = startPageNum;
	
	showCurrentPage();
});

const showCurrentPage = () => {
	$('#deck-viewer').attr({'data-current-page': currentPageIndex });
	$('.deck-viewer-current-page').html(currentPageIndex + 1);
	$('.deck-viewer-page-count').html(totalPagesCount);
	
	updateQuestionCount();
	
	recordViewStart();

	showHidePagers();
	
	showSlideShowPage(currentPageIndex);

	removeQuestionButtonHighlight();
	startSlideDwellTimer();

	if (promptOnLastPage && currentPageIndex === totalPagesCount - 1) {
		handleLastSlide();
	}
}

const updateQuestionCount = () => {
	const viewer = $('#deck-viewer');
	const dpcId = viewer.attr('data-deck-page-conversation-id');
	
	$.get(`/guild/fundraises/${fundraiseId}/question_count.json?page_num=${currentPageIndex}&dpc_id=${dpcId}`, (data) => {
		console.log("PAGE COUNT", data);
		$(".deck-view-question-count").html(data.count > 0 ? `&nbsp;(${data.count})` : '');
	});
}

const loadSlideShowPage = (pageNum) => {
	const pageDiv = $(`.deck-slideshow-page[data-page-num=${pageNum}]`);
	if (pageDiv.length === 0) {
		console.log("loadingSlideShowPage page not found", pageNum);
		return;
	}
	
	if (pageDiv.find('img').attr('src')) {
		console.log("loadingSlideShowPage page already loaded", pageNum);
		return;
	}
	
	// pageDiv.append(`<img src="${pageDiv.attr('data-page-image-url')}">`);

	pageDiv.find('img').attr('src', pageDiv.attr('data-page-image-url'));

	console.log("loadingSlideShowPage page loaded", pageNum);
	pageDiv.find('img').on('load', (e) => {
		console.log("Image loaded - size", e.target.width, e.target.height, e.target.x, e.target.y, e);
		//positionPageLinks(pageNum);
	});
	loadStack.push(pageNum);
	
	if (loadStack.length > maxKeep) {
		unloadSlideShowPage(loadStack.shift(1));
	}
};

const positionPageLinks = (pageNum) => {
	const pageDiv = $(`.deck-slideshow-page[data-page-num=${pageNum}]`);
	if (pageDiv.length === 0) {
		console.log("positionPageLinks page not found", pageNum);
		return;
	}

	const pageImage = pageDiv.find('img');

	const pageX = pageImage[0].offsetLeft;
	const pageY = pageImage[0].offsetTop;
	const pageWidth = pageImage[0].width;
	const pageHeight = pageImage[0].height;

	console.log("SIZES", pageX, pageY, pageWidth, pageHeight);

	pageDiv.children('.deck-slide-link').each((index, link) => {
		let linkEl = $(link);
		linkEl.css({left:`${(linkEl.attr('data-x') * pageWidth) + pageX}px`,
			top: `${linkEl.attr('data-y') * pageHeight}px`,
			width: `${linkEl.attr('data-width') * pageWidth}px`,
			height: `${linkEl.attr('data-height') * pageHeight}px`
		});
		linkEl.attr("data-positioned", 'true');
		console.log(index, link);
	});
}

const unloadSlideShowPage = (pageNum) => {
	console.log('unloadSlideShowPage', pageNum);
	const pageDiv = $(`.deck-slideshow-page[data-page-num=${pageNum}]`);
	pageDiv.html = "";
}

const showSlideShowPage = (pageNum) => {
	for (let num = pageNum - 1; num < pageNum + pagePreload; num += 1) {
		loadSlideShowPage(num);
	}

	$(".deck-slideshow-page").addClass('hidden');
	$(`.deck-slideshow-page[data-page-num=${pageNum}]`).removeClass('hidden');

	positionPageLinks(pageNum);
}

const handleLastSlide = () => {
	window.setTimeout(() => {
		$(".deck-viewer-last-slide-modal").removeClass('hidden');
		promptOnLastPage = false;
	}, 2000);
}

const startSlideDwellTimer = () => {
	cancelSlideDwellTimer();
	slideDwellTimer = window.setTimeout(() => {
		highlightQuestionButton();
	}, 5000);
}

const highlightQuestionButton = () => {
	if (questionButtonHighlightCount < 5 && !questionButtonClicked) {
		$(".deck-review-question-button").addClass("decksend-button-highlight");
		questionButtonHighlightCount += 1;
	}
}

const removeQuestionButtonHighlight = () => {
	$(".deck-review-question-button").removeClass("decksend-button-highlight");
}

$(document).on('click', ".deck-review-question-button", (event) => {
	hideCallouts();
	questionButtonClicked = true;
	removeQuestionButtonHighlight();
});


const cancelSlideDwellTimer = () => {
	if (slideDwellTimer) {
		window.clearTimeout(slideDwellTimer);
		slideDwellTimer = undefined;
	}
}

const hideCallouts = () => {
	$(".decksend-callout").addClass('hidden');
}

const showHidePagers = () => {
	if (currentPageIndex == 0) {
		$(".deck-pager-prev > div").addClass('hidden');
	} else {
		$(".deck-pager-prev > div").removeClass('hidden');
	}

	if (currentPageIndex == totalPagesCount - 1) {
		$(".deck-pager-next > div").addClass('hidden');
	} else {
		$(".deck-pager-next > div").removeClass('hidden');
	}

}

const prevPage = () => {
	hideCallouts();
	if (currentPageIndex === 0) {
		return;
	}
	recordViewEnd();
	
	currentPageIndex -= 1;
	
	showCurrentPage();
}

const nextPage = () => {
	hideCallouts();
	if (currentPageIndex === totalPagesCount - 1) {
		return;
	}
	recordViewEnd();

	currentPageIndex += 1;
	
	showCurrentPage();
}

const goToPage = (pageIndex) => {
	if (currentPageIndex === pageIndex) {
		return;
	}
	
	recordViewEnd();
	currentPageIndex = pageIndex;
	showCurrentPage();
}

$(document).on("click", ".deck-pager-prev", (event) => {
	prevPage();
});

$(document).on("click", ".deck-pager-next", (event) => {
	nextPage();
});

$(document).keydown((event) => {
	if (!$('#deck-viewer-questions').hasClass('hidden')) { // don't navigate if the question pane is open
		if (event.keyCode === 27) {
			$('#deck-viewer-questions').addClass('hidden');
		}
		return;
	}

	if ($('textarea, input[type=text]').is(':focus')) { // Don't navigate if a text input is focused
		return;
	}

	if (event.keyCode === 37) {
		prevPage();
	}
	if (event.keyCode === 39 || event.keyCode === 32 || event.keyCode === 13) {
		nextPage();
	}
});

const recordViewStart = () => {
	console.log(`Page ${currentPageIndex} view start`);
	if (!recordPageViews) {
		console.log("Skipping page view record");
		return;
	}
	$.post(`/guild/fundraises/${fundraiseId}/record_view_start`, {
		page_num: currentPageIndex,
		screen_width: window.innerWidth,
		screen_height: window.innerHeight,
		screen_pixel_ratio: window.devicePixelRatio
	});
}

const recordViewEnd = () => {
	console.log(`Page ${currentPageIndex} view end`);
	if (!recordPageViews) {
		console.log("Skipping page view record");
		return;
	}
	$.post(`/guild/fundraises/${fundraiseId}/record_view_end`, {
		page_num: currentPageIndex,
		screen_width: window.innerWidth,
		screen_height: window.innerHeight,
		screen_pixel_ratio: window.devicePixelRatio
	});
}

var lastTouchStartX = undefined;
$(document).on('touchstart', '#deck-slideshow-container', (event) => {
	if (event.touches.length > 1) {
		return; // two finders on screen so maybe zooming
	}
	lastTouchStartX = event.originalEvent.pageX;
});

$(document).on('touchend', '#deck-slideshow-container', (event) => {
	if (event.touches.length > 1) {
		return; // two finders on screen so maybe zooming
	}

	const delta = event.originalEvent.pageX - lastTouchStartX;

	if (delta >= 30) {
		prevPage();
		return;
	}
	
	if (delta <= -30) {
		nextPage();
	}
});

$(document).on('click', '.decksend-page-link', (event) => {
	goToPage(parseInt($(event.currentTarget).attr('data-page-num')));
	$(".guild-modal-container").addClass('hidden');
	$('#deck-viewer-questions').addClass('hidden');
});

