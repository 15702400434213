console.log("dnd.js load");

$(document).on('dragover', '.guild-file-dropzone', function(event) {
    console.log('dragover');
    event.preventDefault();
    event.stopPropagation();
    $(this).addClass("dragover");
});
$(document).on('dragleave', '.guild-file-dropzone', function(event) {
    console.log('dragleave');
    event.preventDefault();
    event.stopPropagation();
    $(this).removeClass("dragover");
});

var _lobsterFile = undefined;
$(document).on('drop', '.guild-file-dropzone', function(event) {
    event.preventDefault();
    event.stopPropagation();
    $(this).removeClass("dragover");
    const files = event.originalEvent.dataTransfer.files;

    console.log(event.originalEvent.dataTransfer.files[0]);

    const formElement = $(event.currentTarget).parents('form')[0];
    const formData = new FormData(formElement);
    formData.set("fundraise[deck]", files[0]);

    _lobsterFile = files[0];

    // var file = files[0];
    // var formData = new FormData();
    // formData.append("file", file);
    // $.ajax({
    //     url: "/guilds/1/upload",
    //     type: "POST",
    //     data: formData,
    //     processData: false,
    //     contentType: false,
    //     success: function(data) {
    //         console.log("success");
    //     }
    // });
});

$(document).on("click", '.guild-dropzone-inner', function(event) {
    console.log("CLICKE", $(event.currentTarget).parents('.guild-file-dropzone'));
    event.preventDefault();
    event.stopPropagation();
   // $(event.currentTarget).parents('.guild-file-dropzone').find('input[type="file"]').click();

    const formElement = $(event.currentTarget).parents('form')[0];
    const formData = new FormData(formElement);
    console.log(formData.get("fundraise[deck]"));
    console.log('lobster file', _lobsterFile);
});

// Prevent default on the form submit and build new FormData object