import {
	QuillEditors
} from 'packs/quill-editor';
import * as UserSummaryPopup from 'packs/user-summary-popup.js';
import {
	remoteRefresh,revealShare
} from 'packs/remote';

// Edge has loaded - open the Comment thread if it's currently being highlighted
$(document).on("edge:load", (event) => {
	const highlightId = highlightEdgeId();
	const edgeId = event.refreshedElement.attr("data-edge-id")
	revealShare();

	if (edgeId === highlightId) {
		const thread = event.refreshedElement.find(".guild-edge-comment-thread").first();
		remoteRefresh(thread);
		findEdge(edgeId)[0].scrollIntoView(true);
		window.setTimeout(() => {
			findEdge(edgeId)[0].scrollIntoView(true);
			
		}, 
			1000);
	}
});

// There's a new Edge to show, so insert it at the top of the Feed
$(document).on("edge:new", (event) => {
	const feed = $("#guild_content_container").find(".guild-edge-feed").first();
	feed.prepend(event.edgeHTML);
});

// Find an Edge element by Id
const findEdge = (edgeId) => {
	return ($(`.guild-edge[data-edge-id=${edgeId}]`));
}

// An Edge has been deleted, so find it and remove it from the feed
$(document).on("edge:deleted", (event) => {
	const edge = findEdge(event.edgeId);

	edge.fadeOut(400, () => {
		edge.remove();
	});
});

// Load the comment thread for an Edge
$(document).on("click", ".guild-edge-comment-action, .guild-edge-comment-count", (event) => {
	const thread = $(event.target).parents(".guild-edge").find(".guild-edge-comment-thread").first();
	if (thread.attr("loaded")) {
		thread.toggleClass("hidden");
	} else {
		remoteRefresh(thread);
	}
});

// Page has loaded - Initialize the editor at the top of the thread (if any), and scroll to any highlighted Edge  
$(document).on("turbolinks:load", (event) => {
	const form = $(document).find(".guild-edge-form-editor").first();

	const nameableSelector = form.parents(".guild-edge-form-editor-container").find(".guild-nameable-selector");

	if (form.length > 0) {
		const editor = QuillEditors.createEditor(form[0]);
		editor.on('text-change', (delta, oldDelta, source) => {
			if (editor.getText().length > 1) {
				nameableSelector.slideDown();
			} else {
				nameableSelector.slideUp();
			}
		});
	}

	const highlightId = highlightEdgeId();
	
	if (highlightId) {
		console.log("Trying to highlight edge id", highlightId);
		
		const edge = findEdge(highlightId);

		if (edge.length > 0) {
			console.log("Found edge - doing scrollintoview", edge[0]);
			
			// edge[0].scrollIntoView({
			// 	block: "nearest"
			// });
			edge[0].scrollIntoView(true);
		}
	}
});

const highlightEdgeId = () => {
	const url = new URL(window.location);

	return (url.searchParams.get("eid"));
}

// Post a new Comment Edge and display it in the feed
const postComment = (editorData) => {
	$.post({
		url: '/guild/edges',
		data: JSON.stringify(editorData),
		contentType: "application/json",
		processData: false,
		success: (data) => {
			$(document).trigger({
				type: "edge:new",
				edgeHTML: data
			});
			QuillEditors.clearEditor($(document).find(".guild-edge-form-editor")[0]);
			$(".edge-post-button.loading").removeClass("loading");
		}
	});
} 

// Update an existing Comment Edge
const updateComment = (edgeId, editorData) => {
	$.ajax({
		url: `/guild/edges/${edgeId}`,
		method: "PATCH",
		data: JSON.stringify(editorData),
		contentType: "application/json",
		processData: false,
		success: (data) => {
			const oldEdge = findEdge(edgeId);
			oldEdge.replaceWith(data);
		}
	});
}

// Click on the Post button
$(document).on("click", ".edge-post-button", (event) => {
	const form = $(event.currentTarget).parents(".guild-edge-form");

	if (form.attr("data-commentable-id") === '') {
		form.find(".guild-edge-post-no-tag").removeClass("hidden");
		return;
	} else {
		form.find(".guild-edge-post-no-tag").addClass("hidden");
	}

	if ($(event.currentTarget).hasClass("loading")) {
		return;
	}
		
	$(event.currentTarget).addClass("loading");

	const editor = form.find(".guild-edge-form-editor");
	const editorData = QuillEditors.extractFromEditor(editor[0]);
	
	console.log("EDITOR DATA", editorData);

	editorData.commentable_id = form.attr("data-commentable-id");
	editorData.commentable_type = form.attr("data-commentable-type");

	postComment(editorData);
});

// Click on the Edit button
$(document).on("click", ".guild-edge-edit-action", (event) => {
	const edgeContainer = $(event.currentTarget).parents(".guild-edge");
	const edgeId = edgeContainer.attr("data-edge-id");

	$.get(`/guild/edges/${edgeId}/edit_form`, (data) => {
		edgeContainer.append(data);
		edgeContainer.find(".guild-edge-content").addClass("hidden");
		edgeContainer.find(".guild-edge-footer").addClass("hidden");

		const form = edgeContainer.find(".guild-edge-form-editor").first();
		const quillOperations = edgeContainer.find(".guild-edge-quill-ops").text();

		QuillEditors.createEditor(form[0], JSON.parse(quillOperations));
	});
});

// Click on the Save Changes button
$(document).on("click", ".edge-save-button", (event) => {
	const edgeContainer = $(event.currentTarget).parents(".guild-edge");
	const edgeId = edgeContainer.attr("data-edge-id");
	const form = edgeContainer.find(".guild-edge-form")
	const editor = form.find(".guild-edge-form-editor");
	const editorData = QuillEditors.extractFromEditor(editor[0]);
	QuillEditors.disableEditor(editor[0]);

	updateComment(edgeId, editorData);
});

// Click on Delete
$(document).on("click", ".guild-edge-delete-action", (event) => {
	$(event.currentTarget).parents(".guild-edge-footer-actions").find(".guild-edge-confirm-delete-action").removeClass("hidden");
	$(event.currentTarget).parents(".guild-edge-footer-actions").find(".guild-edge-cancel-delete-action").removeClass("hidden");
	$(event.currentTarget).parents(".guild-edge-footer-actions").find(".guild-edge-delete-action").addClass("hidden");
});

// Confirm Delete
$(document).on("click", ".guild-edge-confirm-delete-action", (event) => {
	const edgeId = $(event.currentTarget).parents(".guild-edge").attr("data-edge-id");

	$.ajax({
		method: "DELETE",
		url: `/guild/edges/${edgeId}`,
		success: function(data) {
			$(document).trigger({
				type: "edge:deleted",
				edgeId: edgeId
			});
		},
		error: function(XMLHttpRequest, textStatus, errorThrown) {},
	});
});

// Cancel Delete
$(document).on("click", ".guild-edge-cancel-delete-action", (event) => {
	$(event.currentTarget).parents(".guild-edge-footer-actions").find(".guild-edge-confirm-delete-action").addClass("hidden");
	$(event.currentTarget).parents(".guild-edge-footer-actions").find(".guild-edge-cancel-delete-action").addClass("hidden");
	$(event.currentTarget).parents(".guild-edge-footer-actions").find(".guild-edge-delete-action").removeClass("hidden");
});

