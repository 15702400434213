import Chart from "chart.js/auto";

import {QuillEditors} from 'packs/quill-editor';

console.log("Fundraise.js load");

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, -100%)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

	const slidePreviewContainer = document.createElement('div');
	slidePreviewContainer.innerHTML = `<div class="slide-preview-canvas-container"><div></div></div>`;
	tooltipEl.appendChild(slidePreviewContainer);

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

// Mouse over on fundraise view chart
const externalTooltipHandler = (context) => {
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;
	  th.style.background = 'none';
      const text = document.createTextNode(`Slide #${title}`);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

	// Preview slide
	let pdf = tooltip.dataPoints[0].dataset.pdf;
	if (!pdf) {
		pdf = chart.pdf;
	}
	if (pdf) {
		const slideNum = parseInt(tooltip.title[0]);

		pdf.getPage(slideNum).then((page) => {
				const canvasContainer = tooltipEl.querySelector('.slide-preview-canvas-container');
				canvasContainer.firstChild.remove();
				canvasContainer.innerHTML = '<canvas></canvas>';
				
				const scale = 1;
				const pdfViewport = page.getViewport({ scale: scale, });
				// Support HiDPI-screens.
				const outputScale = window.devicePixelRatio || 1;

				const container = canvasContainer;
				const canvas = container.children[0];
				const context = canvas.getContext('2d');
				
				// console.log("Sizes", outputScale, pdfViewport.width, pdfViewport.height, container.width, container.height);

				canvas.width = Math.floor(pdfViewport.width * outputScale);
				canvas.height = Math.floor(pdfViewport.height * outputScale);
				// canvas.style.width = Math.floor(container.width) + "px";
				// canvas.style.height =  Math.floor(container.height) + "px";

				const transform = outputScale !== 1
				  ? [outputScale, 0, 0, outputScale, 0, 0]
				  : null;

				const renderContext = {
				  canvasContext: context,
				  transform: transform,
				  viewport: pdfViewport
				};
				page.render(renderContext);
		});
	}

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  const yScrollPos = $("#tools-main-content")[0].scrollTop;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = (positionY - yScrollPos) + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

};

$(document).on("deck-view-chart:load", (event) => {
	const el = $(event.refreshedElement);
	const pageViewTimes = eval(el.attr('data-page-view-times'));
	const pageViewSlideNums = eval(el.attr('data-page-view-slide-nums'));
	const deckUrl = el.attr('data-deck-url');

	const ctx = $(event.refreshedElement).find('canvas')[0].getContext('2d');
	const chart = new Chart(ctx, {
	    type: 'bar',
	    data: {
	        labels: pageViewSlideNums,
	        datasets: [{
	            label: 'Seconds on slide',
	            data: pageViewTimes,
	            backgroundColor: 'rgba(0, 0, 255, 0.4)'
	        }]
	    },
	    options: {
	        scales: {
				x: {
					title: {
						display: true,
						text: 'Slide #'
					}
				},
	            y: {
	                beginAtZero: true
	            }
	        },
			interaction: {
				intersect: false,
				mode: 'index',
			},
		    plugins: {
		    	tooltip: {
					enabled: false,
					position: 'nearest',
					external: externalTooltipHandler
		    	}
		    }
	    }
	});
	
	
	if (!chart.pdf) {
		const loadingTask = pdfjsLib.getDocument(deckUrl);
		loadingTask.promise.then(function(pdf) {
			chart.pdf = pdf;
			console.log("InitPDF Viewer - total pages:", pdf.numPages);
		});
	}
});

const perf_data_labels = {
	times: 'Avg seconds on slide',
	users: '% viewers reached slide'
};

const perf_data_colors = [
	'red',
	'blue',
	'green',
	'orange',
	'grey',
];

// Hide question pane by clicking outside it
$(document).on("click", "#deck-viewer", (event) => {
	$('#deck-viewer-questions').addClass('hidden');
});

const createPerformanceChart = (container) => {
	let data = container.deck_view_data;
	if (!data) {
		return;
	}
	
	let show = $(container).attr('data-show');
	
	let datasets = [];
	let ordered_versions = Object.keys(data).reverse().slice(0, 5); // Most recent 5 versions max
	
	for (let x in ordered_versions) {
		let version = ordered_versions[x];
		let series = {
			label: `Version ${version}`,
			data: data[version][show],
			borderColor: perf_data_colors[version - 1],
	        backgroundColor: perf_data_colors[version - 1],
			hidden: (x > 1)
		};
		datasets.push(series);
		
		let loadingTask = pdfjsLib.getDocument(data[version].deck_url);
		loadingTask.promise.then(function(pdf) {
			series.pdf = pdf;
			console.log("InitPDF Viewer - total pages:", pdf.numPages);
		});
	}

	let ctx = $(container).find('canvas')[0].getContext('2d');

	let chart = new Chart(ctx, {
	    type: 'line',
	    data: {
	        labels: data[1].page_nums,
	        datasets: datasets
	    },
	    options: {
	        scales: {
				x: {
					title: {
						display: true,
						text: 'Slide #'
					}
				},
	            y: {
	                beginAtZero: true,
					title: {
						display: true,
						text: perf_data_labels[show]
					}
	            }
	        },
			interaction: {
				intersect: false,
				mode: 'nearest',
				axis: 'xy'
			},
		    plugins: {
		    	tooltip: {
					enabled: false,
					position: 'nearest',
					external: externalTooltipHandler
		    	}
		    }
	    }
	});
}

$(document).on("turbolinks:load", (event) => {
	for (let x in $(".guild-deck-view-performance-chart")) {
		let container = $(".guild-deck-view-performance-chart")[x];
		createPerformanceChart(container);
	}
});

$(document).on('click', '.deck-review-question-button', (event) => {
	if ($('#deck-viewer-questions').hasClass('hidden')) {
		const viewer = $('#deck-viewer');
		const fundraiseId = viewer.attr('data-fundraise-id');
		const pageNum = viewer.attr('data-current-page');
		const dpcId = viewer.attr('data-deck-page-conversation-id');
		
		const url = `/guild/fundraises/${fundraiseId}/questions?page_num=${pageNum}&dpc_id=${dpcId}`;
		
		$('#deck-viewer-questions').html('');
		
		$.get(url, (data) => {
			$('#deck-viewer-questions').html(data);
			
		    const form = $('#deck-viewer-questions').find(".guild-comment-form-editor").first();

		    const editor = QuillEditors.createEditor(form[0], null, `Ask a question about slide #${parseInt(pageNum) + 1}`);
			editor.focus();
		});
	}

	$('#deck-viewer-questions').toggleClass('hidden');

});

// Invitation successful
$(document).on("ajax:success", ".guild-fundraise-invitation-modal form", (event) => {
	console.log(event);

    $(event.target).parents(".guild-modal-container").first().hide();

	$('#tools-main-content').children().first().before("<div class=\"guild_notice\" data-fade-out><p class=\"notice\">Successfully shared</p></div>");

	window.setTimeout(() => {
		$(".guild_notice").fadeOut();
	}, 3000);
});

// Processing deck after upload
// $(document).on("turbolinks:load", (event) => {
// 	const messages = $(".guild-fundraise-deck-processing-progress").first();
// 	if (messages.length > 0) {
// 		const artifactId = messages.attr('data-artifact-id');
//
// 		const url = `/guild/artifacts/${artifactId}/processing_state`;
//
// 		const poll = () => {
// 			$.get(url, (data) => {
// 				console.log(data);
// 				if (data.state !== 'complete') {
// 					messages.html(`<p>${data.message}</p>`);
// 					window.setTimeout(poll, 2000);
// 				} else {
// 					$(".guild-fundraise-deck-processed").removeClass('hidden');
// 					$(".guild-fundraise-deck-processing").addClass('hidden');
//
// 					if (data.ai_summary) {
// 						$(".guild-fundraise-deck-processed-ai-insights").removeClass('hidden');
// 						$(".guild-fundraise-deck-processed-ai-insights-summary").html(data.ai_summary);
// 					}
// 				}
// 			});
// 		};
//
// 		poll();
// 	}
// });