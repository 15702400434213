// Search using Searchable concern and SearchableController

var incrementalSearchTimer = undefined;

$(document).on("keyup", ".guild-searchable-bar-input-container > input", (event) => {
	if (incrementalSearchTimer) {
		window.clearTimeout(incrementalSearchTimer);
		incrementalSearchTimer = undefined;
	}

	const container = $(event.currentTarget).parents(".guild-searchable-bar");
	const loadingAnimation = $(container).find(".loading");
	const closeAction = $(container).find(".close-action");
	const results = $(container).find(".guild-searchable-bar-results-container").first();
	const incrementalUrl = $(container).attr('data-search-incremental-url');
	const fullUrl = $(container).attr('data-search-full-url');
	
	if (event.key === 'Escape') {
		$(event.currentTarget).val("");
		loadingAnimation.addClass('hidden');
		closeAction.addClass('hidden');
		$(results).html('');
		return;
	}
	
	closeAction.removeClass('hidden');

	let query = $(event.currentTarget).val();

	if (query.length < 3) {
		$(results).html('');
		return;
	}
	
	if (event.key === 'Enter' && fullUrl) {
		location.href = `${fullUrl}&q=${query}`;
		return;
	}
	
	if (incrementalUrl) {
		incrementalSearchTimer = window.setTimeout(function() {
			incrementalSearchTimer = undefined;
		
			console.log("Search", query);
		
			loadingAnimation.removeClass('hidden');
		
			$.get(incrementalUrl,
				{ q: query },
				function( data ) {
					loadingAnimation.addClass('hidden');
					$(results).html(data);
					results.css({minWidth: $(container).outerWidth() + 'px'});
				}
			);
		
		}, 250);
	}

});

$(document).on("click", ".guild-searchable-bar-input-container .close-action", (event) => {
	const container = $(event.currentTarget).parents(".guild-searchable-bar");
	const loadingAnimation = $(container).find(".loading");
	const closeAction = $(container).find(".close-action");
	const results = $(container).find(".guild-searchable-bar-results-container").first();
	const input = $(container).find('input');

	input.val("");
	loadingAnimation.addClass('hidden');
	closeAction.addClass('hidden');
	$(results).html('');
	
});


// Old User search below
var timer = undefined;
$(document).on("keyup", ".guild-user-search-input", (event) => {
	if (timer) {
		window.clearTimeout(timer);
		timer = undefined;
	}
	
	if (event.key === 'Escape') {
		$(event.currentTarget).val("");
	}
	
	let query = $(event.currentTarget).val();

	const container = $(event.currentTarget).parents(".guild-user-search");
	const results = $(container).find(".guild-user-search-results").first();

	if (query.length < 3) {
		$(results).html('');
		return;
	}
	
	const loadingAnimation = $(container).find(".loading-animation");

	timer = window.setTimeout(function() {
		timer = undefined;
		
		console.log("Search", query);
		
		loadingAnimation.show();
		
		$.get( "/guild/users/search",
			{ q: query },
			function( data ) {
				loadingAnimation.hide();
				$(results).html(data);
				results.css({width: $(event.currentTarget).outerWidth() + 'px'});
			}
		);
		
	}, 250);
});

$(document).on("click", ".guild-user-search-result, .guild-user-search-suggested", (event) => {
	const resultId = $(event.currentTarget).attr("data-result-id");
	const resultType = $(event.currentTarget).attr("data-result-type");
	const resultDetail = $(event.currentTarget).attr("data-result-details");

	console.log("Result click", resultType, resultId, resultDetail);

	const container = $(event.currentTarget).parents(".guild-user-search");
	container.find(`input[name="user_id"]`).val('');
	container.find(`input[name="list_id"]`).val('');

	const idFieldName = (resultType == 'User') ? 'user_id' : 'list_id';
	const idField = container.find(`input[name="${idFieldName}"]`);
	idField.val(resultId);

	const searchField = container.find(".guild-user-search-input");
	searchField.val(resultDetail);

	const results = container.find(".guild-user-search-results").first();
	$(results).html('');
});