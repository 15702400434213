// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/guild")
require("packs/remote")
require("packs/edges")
require("packs/home")
require("packs/surveys")
require("packs/companies")
require("packs/followable")
require("packs/quill-editor")
require("packs/comments")
require("packs/search")
require("packs/forms")
require("packs/rich-text")
require("packs/interestable")
require("packs/company-metrics")
require("packs/cookies-bar")
require("packs/kyndi")
require("packs/fundraises")
require("packs/pdf")
require("packs/effects")
require("packs/data")
require("packs/dnd")



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
